<template>
  <v-dialog v-model="getSessionEndViewer" width="67%" persistent>
    <v-card class="pa-5">
      <!-- <div style="position: absolute; align-self: end; left: 0; top: 0">
        <v-img
          @click="actionOnTap()"
          class="talk-button mt-3 ml-3"
          contain
          width="90px"
          :src="require('../../assets/' + talkButtonSrc)"
        >
        </v-img>
      </div> -->
      <v-card-title class="justify-center text-h4 pb-7 font-weight-bold">
        {{ $t('headerText') }}
      </v-card-title>

      <v-card-text align="center" class="text-h4 font-weight-bold black--text">
        {{ $t('sessionEndMsg1') }} {{ countDown }} {{ $t('sessionEndMsg2') }}
      </v-card-text>
      <v-card-text align="center" class="pa-0">
        <v-card v-for="(item, index) in $t('options')" :key="index" class="my-1" width="85%" @click="viewElement(item)"
          color="primary">
          <v-card-text class="white--text text-h5">
            {{ item }}
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "sessionEndViewer",
  data() {
    return {
      talkButtonSrc: "talkButton.png",
      header: this.$t('headerText'),
      options: this.$t('options'),
      countDown: 15,
    };
  },
  computed: {
    ...mapGetters([
      "getIsSpeaking",
      "speechRecognitionStatus",
      "getQnaStatus",
      "getResetKioskStatus",
      "getSessionEndViewer",
      "getCompleteUserSession",
      "getResetKioskStatus",
      "getSessionId",
      "getKioskProfile"
    ]),
  },
  methods: {
    viewElement(input) {
      // Touch Usage      
      const date = new Date()
      this.$store.dispatch('addUserSessionToStrapi', {
        applicationId: this.getKioskProfile.data().applicationId,
        actionType: 'Touch',
        applicationSessionId: this.getSessionId,
        timeStamp: date.toISOString(),
        sessionPayload: {
          module: "Session End Viewer",
          action: input,
          response: 'Action Executed',
          timeStamp: date.toISOString()
        }
      })
      if (input === "Start Over" || input === "Todavía Estoy Aquí") {
        this.$i18n.locale = 'en'
        this.$store.dispatch("kioskStartOver");
      } else if (input === "I'm still here" || input === "Comenzar de Nuevo") {
        this.$store.dispatch("kioskStillThere");
      }
    },
    closeDialog() {
      this.$store.commit("setSessionEndViewer", false);
      this.$store.dispatch("kioskStillThere");
      window.stopSpeech();
    },
    actionOnTap() {
      if (!this.getIsSpeaking) {
        if (this.getResetKioskStatus) {
          this.$store.commit("setSpeechRecognitionHandler", true);
        } else {
          this.$store.commit("setSpeechRecognitionHandler", true);
        }
      } else {
        window.stopSpeech();
        this.talkButtonSrc = "talkButton.png";
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.countDown = 15
      }
    },
  },
  watch: {
    getIsSpeaking(newVal) {
      if (newVal) {
        this.talkButtonSrc = "stopTalking.png";
      }
      if (!newVal && !this.speechRecognitionStatus) {
        this.talkButtonSrc = "talkButton.png";
      }
    },
    speechRecognitionStatus(newVal) {
      if (newVal) {
        this.talkButtonSrc = "talkButtonActive.webp";
      } else {
        this.talkButtonSrc = "talkButton.png";
      }
    },
    getSessionEndViewer(newVal) {
      if (newVal) {
        this.countDownTimer();
      }
    },
  },
};
</script>

<style scoped>
.talk-button {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%) contrast(350%);
}
</style>
<i18n>
{
  "en": {
      "options": ["I'm still here", "Start Over"],
      "headerText": "Are you still there?",
      "sessionEndMsg1": "Your session will timeout in",
      "sessionEndMsg2": "seconds"
    },
  "es":{
      "options": ["Todavía Estoy Aquí", "Comenzar de Nuevo"],
      "headerText": "¿Sigues ahí?",
      "sessionEndMsg1": "Su sesión expirará en",
      "sessionEndMsg2": "segundos"
    }
}
</i18n>