import gql from 'graphql-tag'

//---------------------------- HomePage Queries ---------------------------------

export const homepageQuery = gql`
query homeQuery($alias : JSON, $lang: String, $location: String) {
  homepages (sort: "displayPosition:asc", where : { alias : $alias, kioskLocationCode_contains: $location }, locale: $lang) {
    id
    speech    
    pageName
    locale 
    alias    
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        textList
        type      
      }
    }
    displayIcon{
      id
      url
    }
  }
}
`

//---------------------------- Faq Page Queries ---------------------------------

export const faqCategoryQuery = gql`
query faqCategoryQuery($lang: String, $category: String) {
  faqCategories(sort: "displayOrder:asc", locale: $lang, where : { categoryName : $category}) {
    id
    categoryName
    displayType
    displayOrder
    locale
  }
}
`

export const faqQuery = gql`
query faqQuery($lang: String) {
  faqs (start: 0, locale: $lang, limit: -1) {
    id
    category
    locale
    showMap
    mapName
    showQrcode
    qrDetails
    LinkID
    question
    answer
    linkKeyword
  }
}
`

export const faqQuery2 = gql`
query faqQuery($lang: String) {
  faqs (start: 100, locale: $lang) {
    id
    category
    locale
    showMap
    mapName
    showQrcode
    qrDetails
    LinkID
    question
    answer
    linkKeyword
  }
}
`

export const faqQuery3 = gql`
query faqQuery($lang: String) {
  faqs (start: 200, locale: $lang) {
    id
    category
    locale
    showMap
    mapName
    showQrcode
    qrDetails
    LinkID
    question
    answer
    linkKeyword
  }
}
`
//---------------------------- Map Page Queries ---------------------------------

export const floorQuery = gql`
query floorQuery($lang: String, $location: String) {
  floors (sort : "floorNumber:ASC", locale: $lang, where : { kioskLocationCode_contains: $location }){ 
    id
    floorName
    floorNumber
    speech
    defaultLocation
    locale
    floorImage {
      id
      url
    }
  }
}
`

export const mapQuery = gql`
query mapQuery($lang: String,  $location: String) {
  maps(locale: $lang, where : { kioskLocationCode_contains: $location }){
    id
    mapName
    mapFloor
    speech
    locale
    displayPosition
    hearingLocationCode
    mapImage {
      id
      url
    }
  }
}
`

