import axios from "axios";
import router from "@/router";
import i18n from "../../i18n";
import moment from "moment";
export default {
  state: {
    qnaStatus: null,
    qnaKeyword: "",
    wrongAnswerCount: 0,
    court: "",
    faqCategory: "",
    isThinking: false,
    requestQna: true,
    mapKeywords: [
      "Probate Court",
      "State Court Records",
      "Probation",
      "Law Library",
      "Court Administration",
      "Clerk's Office",
      "Deed Room",
      "Administration Coordinator",
      "State Magistrate Court Clerk's Office",
      "District Attorney's Office",
      "Sheriff's Office",
      "Courtroom G",
      "Courtroom H",
      "Courtroom I",
      "Elevators",
      "Restroom",
      "Courtroom 2A",
      "Courtroom 2B",
      "Courtroom 2C",
      "Courtroom 2D",
      "Courtroom 3C",
      "Courtroom 3D",
      "Courtroom 5A",
      "Courtroom 5B",
      "Courtroom 5C",
      "Courtroom 5D",
      "Courtroom 6A",
      "Courtroom 6B",
      "Courtroom 6C",
      "Courtroom 6D",
      "Courtroom 7A",
      "Courtroom 7B",
      "Courtroom 7C",
      "Courtroom 7D",
    ],
    navigationKeyword: ["faqs", "maps", "today's hearings"],
    faqSearchKeyword: [
      "request a copy of a civil and family case record",
      "make a filing for an inmate",
      "file a claim over-the-counter",
      "change address in a pending criminal case",
      "criminal case via postal",
      "process for a tax appeal",
      "schedule an appointment to review records",
      "retreiving archived documents or records",
      "request records online",
      "prior to 2011",
    ],
  },
  getters: {
    getQnaStatus: (state) => state.qnaStatus,
    getQnaKeyword: (state) => state.qnaKeyword,
    getWrongAnsCount: (state) => state.wrongAnswerCount,
    getCourt: (state) => state.court,
    getFaqCategory: (state) => state.faqCategory,
    getIsThinking: (state) => state.isThinking,
    getRequestQna: (state) => state.requestQna,
    getMapKeywords: (state) => state.mapKeywords,
    getNavigationKeyword: (state) => state.navigationKeyword,
    getFaqSearchKeyword: (state) => state.faqSearchKeyword,
  },
  mutations: {
    setQnaStatus(state, status) {
      state.qnaStatus = status;
    },
    setQnaKeyword(state, keyword) {
      state.qnaKeyword = keyword;
    },
    setWrongAnswerCount(state, count) {
      state.wrongAnswerCount = count;
    },
    setCourt(state, court) {
      state.court = court;
    },
    setFaqCategory(state, category) {
      state.faqCategory = category;
    },
    setIsThinking(state, status) {
      state.isThinking = status;
    },
    setRequestQna(state, status) {
      state.requestQna = status;
    },
  },
  actions: {
    pullAllQnA({ commit }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        //GET {Endpoint}/language/query-knowledgebases/projects/{projectName}/qnas?api-version=2021-10-01
        url:
          "https://ars-studio-en.cognitiveservices.azure.com/language/query-knowledgebases/projects/" +
          i18n.t("qnaEndpoint").kbName +
          "/qnas?&api-version=2021-10-01&deploymentName=production",
        headers: {
          "Ocp-Apim-Subscription-Key": "da55d74bdec54b7b856aef2a8e8bbded",
          "Content-Type": "application/json",
        },
      };

      axios.request(config).then((response) => {
        console.log("Fetching all QNAS: ", response);
      });
    },
    requestBotAI({ getters, dispatch, commit }, transcript) {
      if (getters.isThinking) {
        commit("setSpeechTranscript", transcript);
        commit("setTextBubbleText", transcript);
      }
      let data = JSON.stringify({
        question: transcript,
        full_source: false,
        format: "text",
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.docsbot.ai/teams/IA1GSIfKA6r2OpN0D4yc/bots/BHVSPtpKdd1xSxNvvzdO/chat",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer 8e353866869831eb427a57f4cb0064514e2d8020df37a3b54f6c6a5dc951146e",
        },
        data: data,
      };
      commit("setIsThinking", true);
      commit("setTextBubbleText", "Thinking....");
      axios
        .request(config)
        .then((response) => {
          dispatch("addUserSessionToStrapi", {
            applicationId: getters.getKioskProfile.data().applicationId,
            applicationSessionId: getters.getSessionId,
            timeStamp: moment().toISOString(),
            actionType: "Speech",
            sessionPayload: {
              transcript: transcript,
              action: "AI Requested",
              response: response.data,
            },
          });
          if (response.data.answer.toLowerCase().includes("red")) {
            let foundKeyword = getters.getMapKeywords.find((keyword) =>
              response.data.answer.toLowerCase().includes(keyword.toLowerCase())
            );
            if (foundKeyword) {
              var mapPath = i18n
                .t("maps")
                .filter(
                  (map) =>
                    map.mapName.toLowerCase() === foundKeyword.toLowerCase()
                );
              dispatch("openImageViewer", {
                name: mapPath[0].mapName,
                url: mapPath[0].mapImage,
              });
              dispatch("avatarSpeak", response.data.answer);
            }
          } else if (response.data.answer.toLowerCase().includes("navigate")) {
            let foundKeyword = getters.getNavigationKeyword.find((keyword) =>
              response.data.answer.toLowerCase().includes(keyword)
            );
            if (foundKeyword) {
              var nagivation = i18n
                .t("homepages")
                .filter((page) => page.pageName.toLowerCase() === foundKeyword);
              commit("setPageHeader", nagivation[0].pageType[0].pageHeader);
              dispatch("avatarSpeak", nagivation[0].speech);
              if (
                router.currentRoute.path !==
                "/" + nagivation[0].pageType[0].pathName
              ) {
                router.push(nagivation[0].pageType[0].pathName);
              }
            }
          } else if (response.data.answer.toLowerCase().includes("sorry")) {
            dispatch("avatarSpeak", response.data.answer);
          } else {
            let foundKeyword = getters.getFaqSearchKeyword.find((keyword) =>
              response.data.answer.toLowerCase().includes(keyword)
            );
            console.log("FAQS", i18n.t("faqs"));
            if (foundKeyword) {
              let faqList = i18n
                .t("faqs")
                .filter((faq) => faq.linkKeyword !== null);
              var faqSearched = faqList.filter(
                (faq) =>
                  faq.linkKeyword.toLowerCase() === foundKeyword.toLowerCase()
              );
              console.log("Searched FAQ: ", faqSearched);
              if (router.currentRoute.path !== "/faqs") {
                router.push("/faqs");
                commit("setPageHeader", "Frequently Asked Questions");
              }

              commit("setQnaKeyword", faqSearched[0].linkId);
              dispatch("avatarSpeak", response.data.answer);
            } else {
              dispatch("avatarSpeak", response.data.answer);
            }
          }
        })
        .catch((error) => {
          commit("setIsThinking", false);
          console.log(error);
        });
    },
    requestQnA({ getters, dispatch, commit }, transcript) {
      // Request to QNA
      if (getters.isThinking) {
        commit("setSpeechTranscript", transcript);
        commit("setTextBubbleText", transcript);
      }
      let data = JSON.stringify({
        top: 3,
        question: transcript,
        includeUnstructuredSources: true,
        confidenceScoreThreshold: "0.2",
        filters: {
          metadataFilter: {
            metadata: [
              {
                key: "scope",
                value: "common",
              },
              {
                key: "scope",
                value: "wf",
              },
            ],
            logicalOperation: "OR",
          },
        },
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:
          "https://arsstudio.cognitiveservices.azure.com/language/:query-knowledgebases?projectName=" +
          i18n.t("qnaEndpoint").kbName +
          "&api-version=2021-10-01&deploymentName=production",
        headers: {
          "Ocp-Apim-Subscription-Key": "e4cde2e3cd954d1bb5ad43686d7d6e24",
          "Content-Type": "application/json",
        },
        data: data,
      };
      commit("setIsThinking", true);
      commit("setTextBubbleText", "Thinking....");
      axios
        .request(config)
        .then((response) => {
          console.log("Response from QNA: ", response.data);
          const date = new Date();
          dispatch("addUserSessionToStrapi", {
            applicationId: getters.getKioskProfile.data().applicationId,
            actionType: "QnA",
            applicationSessionId: getters.getSessionId,
            timeStamp: date.toISOString(),
            sessionPayload: {
              module: "QnA",
              action: transcript,
              response: response.data,
              timeStamp: date.toISOString(),
            },
          });
          if (response.data.answers[0].confidenceScore > 0.6) {
            console.log('before generate anser: ', response.data.answers[0])
            dispatch("generateAnswer", response.data.answers[0]);
          } else if (response.data.answers[0].confidenceScore > 0.45) {
            commit("setIsThinking", false);
            if (getters.getCurrentLanguage === "en") {
              dispatch(
                "avatarSpeak",
                "I didn't quite get that, did you mean to ask following questions. Please tap your preference."
              );
            } else {
              dispatch(
                "avatarSpeak",
                "No entendía eso, querías hacer las siguientes preguntas. Por favor, toque su preferencia."
              );
            }
            let followUp = [];
            response.data.answers.forEach((output) => {
              if (output.confidenceScore > 0.45)
                followUp.push(output.questions[0]);
            });
            var headerVal;
            if (getters.getCurrentLanguage === "en") {
              headerVal = "Please Tap the option you prefer";
            } else {
              headerVal = "Por favor, toque la opción que prefiera";
            }
            dispatch("openOptionsViewer", {
              dialogHeader: headerVal,
              dialogData: followUp,
            });
          } else {
            commit("setIsThinking", false);
            if (getters.getKioskProfile.data().telepresence) {
              commit("setTelepresenceViewer", true);
            } else {
              var mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              console.log("Map Path at error: ", mapPath);
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
              });
              dispatch("avatarSpeak", i18n.t("defaultKBError"));
            }
          }
        })
        .catch((error) => {
          commit("setIsThinking", false);
          console.log(error);
        });
    },
    generateAnswer({ dispatch, commit, getters }, result) {
      console.log('Result at generate answer: ', result)
      if (getters.getIsThinking) {
        commit("setQnaStatus", false);
        const metadata = result.metadata.intent;
        const ansObj = result;
        commit("setDefaultBubbleText", false);
        commit("closeOptionsViewer");
        switch (metadata) {
          case "navigate":
            var menuName = ansObj.metadata.navigateto;
            router.addRoute({
              path: menuName.toLowerCase(),
              component: () => import("@/views/" + menuName),
            });
            if (router.currentRoute.path !== menuName) {
              router.push(menuName).then(() => {
                commit("setQnaKeyword", "");
                dispatch("avatarSpeak", ansObj.answer);
                let page = i18n
                  .t("homepages")
                  .filter((page) => page.pageType[0].pathName === menuName);
                commit("setPageHeader", page[0].pageType[0].pageHeader);
              });
            } else {
              commit("setQnaKeyword", "");
              dispatch("avatarSpeak", ansObj.answer);
            }
            break;

          case "showmap":
            try {
              var mapPath = i18n
                .t("maps")
                .filter(
                  (map) =>
                    map.displayPosition.toString() ===
                    ansObj.metadata.mapposition
                );
              console.log("Map Path: ", mapPath);
              dispatch("openImageViewer", {
                name: mapPath[0].mapName,
                url: mapPath[0].mapImage,
              });
              if (ansObj.answer.trim() !== "default") {
                dispatch(
                  "avatarSpeak",
                  ansObj.answer + " " + mapPath[0].speech
                );
              } else {
                dispatch("avatarSpeak", mapPath[0].speech);
              }
            } catch (e) {
              mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
              });
              dispatch("avatarSpeak", i18n.t("mapNotFound"));
            }

            break;

          case "speak":
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "searchbykeyword":
            commit("setQnaKeyword", "");
            router.addRoute({
              path: "/faqs",
              component: () => import("@/views/faqs"),
            });
            if (router.currentRoute.path !== "/faqs") {
              router.push("/faqs");
            }
            var page = i18n
              .t("homepages")
              .filter((page) => page.pageType[0].pathName === "faqs");
            commit("setPageHeader", page[0].pageType[0].pageHeader);
            var linkId = ansObj.metadata.linkid;
            console.log("Linkid : ", linkId);
            commit("setQnaKeyword", linkId);
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showfaqcategory":
            var faqKeyword = ansObj.metadata.keyword;

            router.addRoute({
              path: "/faqs",
              component: () => import("@/views/faqs"),
            });
            if (router.currentRoute.path !== "/faqs") {
              router.push("/faqs");
            }
            page = i18n
              .t("homepages")
              .filter((page) => page.pageType[0].pathName === "faqs");
            commit("setPageHeader", page[0].pageType[0].pageHeader);

            commit("setSelectedFaqCat", {
              name: faqKeyword,
              type: "expansionPanel",
            });
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showformpackets":
            var packetInfo = getters.getFormPacketsEnglish.get(
              ansObj.metadata.find(({ name }) => name === "keyword").value
            );
            dispatch("openPdfViewer", {
              url: packetInfo.packetFiles,
              name: packetInfo.packetName,
              desc: packetInfo.packetDesc,
              emailLink: packetInfo.packetLink,
              type: "Packet",
            });
            //dispatch('avatarSpeak', ansObj.answer);
            break;

          case "showemail":
            var url = ansObj.metadata.url;
            var docName = ansObj.metadata.name;
            dispatch("openEmailViewer", {
              pdfType: "link",
              pdfName: docName,
              pdfUrl: "https://" + url,
            });
            dispatch("avatarSpeak", ansObj.answer);
            break;
          case "openurl":
            console.log('Coming inside open url', ansObj.metadata)
            var cms = ansObj.metadata.cms
            if(cms === 'homepages'){
              let link = i18n
              .t("homepages")
              .filter((page) => page.alias === ansObj.metadata.navigateto);
              console.log('WHat is in the link: ', link)
              window.open(link[0].pageType[0].textList)
              dispatch("avatarSpeak", ansObj.answer);
            }
            break;
          case "followup":
            var prompts = ansObj.dialog.prompts;
            var followUp = [];
            dispatch("avatarSpeak", ansObj.answer);
            prompts.forEach((item) => followUp.push(item.displayText));
            dispatch("openOptionsViewer", {
              dialogHeader: i18n.t("selectOptionMsg"),
              dialogData: followUp,
            });
            break;
          default:
            mapPath = getters.getMaps.get(
              getters.getDefaultLocation.toLowerCase()
            );
            dispatch("openImageViewer", {
              name: mapPath.mapName,
              url: mapPath.mapImage,
            });
            dispatch("avatarSpeak", i18n.t("kbError"));
            break;
        }
      }
    },
  },
};
