
module.exports = {
  firebase: {
    apiKey: "AIzaSyC6So-FAFcdIYVQvjpUFYFXgioJdV7E4D4",
    authDomain: "dekalb-628b8.firebaseapp.com",
    databaseURL: "https://dekalb-628b8-default-rtdb.firebaseio.com",
    projectId: "dekalb-628b8",
    storageBucket: "dekalb-628b8.appspot.com",
    messagingSenderId: "942782557764",
    appId: "1:942782557764:web:85226ae6b181d41c2e9611"
  }
}

